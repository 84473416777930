.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    height: 100vh;

    @media screen and (max-width: 1000px) {
        width: auto !important;
    }

    .right_section {
        .icons {
            display: flex;
            align-items: center;
            gap: 16px;
            .login {
                margin-right: 0;
            }

            .divider {
                width: 2px;
                height: 48px;
                background: rgb(60, 59, 59);
            }
            svg {
                color: #fff;
            }
        }

        .login {
            margin-right: 50px;
            display: flex;
            align-items: center;
            gap: 4px;
            color: #fff;
            cursor: pointer;
            font-family: 'Poppins', sans-serif;

            p {
                font-size: 18px;
                line-height: 120%;
                letter-spacing: 1px;
            }

            svg {
                scale: 1.1;
            }
        }
    }
}

.object{
    z-index: 2000;
}