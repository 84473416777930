.container {
    margin: 0;

    .container_header {
        position: fixed;
        z-index: 999;
        height: 50px;
        top: 0;
        overflow: hidden;
        background: #fff;
        width: 100%;
        // box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 100px;

        img {
            width: 100px;
        }

        .link {
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            line-height: 120%;
            font-weight: 600;
            color: rgba(33, 37, 41, 0.5);

            a {
                text-decoration: none;
                color: #212529;
            }
        }
    }

    .login {
        margin: 0;
        padding: 0 100px;
        background: #ffffff;
        position: relative;

        .header {
            width: 100%;
            height: 180px;
            padding-top: 50px;
            display: flex;
            align-items: center;
            background: #fff;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            font-family: 'Poppins', sans-serif;
            z-index: 10;

            .left {
                .header_heading {
                    font-size: 40px;
                    font-weight: 600;
                    margin: 0;
                    line-height: 50px;
                    color: #212529;
                }

                p {
                    font-size: 24px;
                    margin: 20px 0 0 0;
                    color: #212529;
                    opacity: 0.6;
                }
            }
        }
    }
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .inputs {
        display: flex;
        gap: 20px;
        flex-direction: column;

        fieldset {
            border-radius: 0;
        }

        .input {
            font-weight: 400;
            background: #fff;
        }

        .error_message {
            font-size: 12px;
            line-height: 120%;
        }
    }

    .button {
        background: #000;
        border-radius: 0;
    }
}

.error_box {
    border-color: rgb(255, 205, 199) rgb(255, 205, 199) rgb(255, 205, 199) rgb(219, 48, 48);
    background-color: rgb(255, 234, 229);
    color: rgb(151, 6, 6);
    width: 100%;
    height: fit-content;
    border-radius: 5px;
    font-size: 13px;
}

.success_box {
    background-color: rgb(190 245 218);
    color: rgb(40 88 54);
    width: 100%;
    height: fit-content;
    border-radius: 5px;
    font-size: 13px;
}