.container {
    height: 100vh;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;

    .circle_one {
        position: relative;
        width: 550px;
        height: 550px;

        .co_sph_one,
        .co_sph_two,
        .co_sph_three,
        .co_sph_four {
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background: linear-gradient(90deg, #5293E6 0%, #1B4BB0 100%);
            box-shadow: 6px 15px 23.2px 5px rgba(63, 122, 212, 0.2);
            z-index: 20;
        }

        .co_sph_one {
            top: -7px;
            left: 50%;
            margin-left: -0.5rem;
        }

        .co_sph_two {
            right: -7px;
            top: 50%;
            margin-top: -0.5rem;
        }

        .co_sph_three {
            bottom: -7px;
            left: 50%;
            margin-left: -0.5rem;
        }

        .co_sph_four {
            left: -7px;
            top: 50%;
            margin-top: -0.5rem;
        }
    }

    .circle_two {
        position: absolute;
        width: 200px;
        height: 200px;
        top: 50%;
        left: 50%;
        margin-top: -100px;
        margin-left: -100px;

        .ct_sph_one,
        .ct_sph_two {
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background: linear-gradient(90deg, #5293E6 0%, #1B4BB0 100%);
            box-shadow: 6px 15px 23.2px 5px rgba(63, 122, 212, 0.2);
            z-index: 20;
        }

        .ct_sph_one {
            top: -7px;
            left: 50%;
            margin-left: -0.5rem;
        }

        .ct_sph_two {
            bottom: -7px;
            left: 50%;
            margin-left: -0.5rem;
        }
    }

    @media screen and (max-width: 600px) {
        .circle_one {
            width: 350px;
            height: 350px;
        }

        .circle_two {
            width: 150px;
            height: 150px;
            top: 57%;
            left: 57%;
        }
    }

    @media screen and (max-width: 350px) {
        .circle_one {
            width: 280px;
            height: 280px;
        }

        .circle_two {
            width: 160px;
            height: 160px;
        }
    }
}