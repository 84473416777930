@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.container {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    margin-right: 0%;
    background-size: cover;

    .body {
        display: flex;
        // align-items: center;
        justify-content: center;
        max-width: 1346px;
        margin: 0 auto;
        flex-direction: column;
        padding-top: 180px;

        .content {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;
            width: 70%;
            max-width: 1346px;

            h1 {
                margin-top: 20px;
                margin-bottom: 10px;
                font-family: 'Poppins', sans-serif;
                font-size: 65px;
                font-weight: 400;
                line-height: 120%;
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 300;
                line-height: 150%;
                width: 65%;

                @media screen and (max-width: 650px) {
                    width: auto;
                }
            }
        }

        .register_section {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 70%;
            max-width: 1346px;

            h3 {
                // color: rgba(0, 0, 0, 0.9);
                margin: 0;
                padding: 0;
                font-size: 24px;
                line-height: 120%;
                font-weight: 600;
                // font-family: 'Poppins', sans-serif;
            }

            .buttons {
                display: flex;
                gap: 20px;
                margin-top: 24px;

                a {
                    text-decoration: none;
                }

                button {
                    width: 250px;
                    padding: 16px 10px;
                    background: #fff;
                    color: #111;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 120%;
                    // font-family: 'Poppins', sans-serif;
                    cursor: pointer;
                    border: none;
                    outline: none;
                    transition: transform ease-in 0.1s;

                    &:hover {
                        transform: scale(0.95);
                    }
                }
            }

            .signin {
                margin: 16px 0 0 0;
                padding: 0;
                // font-family: 'Poppins', sans-serif;
                font-size: 16px;
                line-height: 120%;
                font-weight: 600;
                // color: rgba(33, 37, 41, 0.5);

                button {
                    color: #fff;
                    text-decoration: underline;
                    cursor: pointer;
                    outline: none;
                    border: none;
                    // font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    line-height: 120%;
                    font-weight: 600;
                    background: none;
                    // color: #212529;
                }
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .container {
        .body {
            padding: 0;

            .content {
                max-width: 100%;
                width: 90%;
                margin: auto;
                text-align: center;
                align-items: center;

                h1 {
                    font-size: 34px;
                    font-weight: 500;
                }

                p {
                    font-size: 18px;
                    font-weight: 300;
                }
            }

            .register_section {
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
                max-width: 100%;

                .buttons {
                    flex-direction: column;
                }
            }
        }
    }
}