.contactUsBg{
    background-image: url('../../../assets/images/contactUsImage.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.contactUsBg::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(16 47 64 / 50%); /* Adjust the opacity as needed */
    z-index: 0;
    color: white;
}

.contactUsBg > * {
    position: absolute;
    z-index: 2;
}

.contactUsForm{
    margin-top: -40px !important;
}