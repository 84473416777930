.offline-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #191919;
  color: white;
  text-align: center;
  padding: 3px;
  z-index: 2000;
  animation: slideUp 0.5s ease-out;

}

.offline-text {
  margin: 0;
  font-weight: bold;
}

.online-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: green;
  color: white;
  text-align: center;
  padding: 3px;
  z-index: 2000;
  opacity: 1;
}

.online-text {
  margin: 0;
  font-weight: bold;
}


@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}