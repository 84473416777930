.dialog {
    border-radius: 5px;
    position: relative;
    padding: 16px 16px;
    max-width: 500px !important;

    .close {
        position: absolute;
        right: 10px;
        cursor: pointer;
        background: transparent;
        border: none;
        outline: none;
    }
}

.loginform {
    width: 60%;
    background-color: #ffffff;
    border-radius: 30px 0px 0px 30px;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 10%;
    padding-right: 10%;
    height: 100vh;
    position: absolute;
    z-index: 1000;
    overflow: visible;

    @media screen and (max-width: 1000px) {
        width: 100% !important;
        margin-right: 0% !important;
        right: 0rem;
        top: 8rem;
        border-radius: 20px 20px 0px 0px;

        height: 90vh;
        padding-bottom: 8rem;
        overflow: auto;
    }

    @media screen and (max-width: 700px) {
        width: 100% !important;
        margin-right: 0% !important;
        padding-left: 4%;
        padding-right: 4%;
    }

    @media screen and (max-width: 500px) {
        width: 100% !important;
    }

    .logo {
        @media screen and (max-width: 500px) {
            justify-content: center;
        }
    }


    .skip {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .msg_dialog {
        min-height: 200px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 24px;
        width: 100%;

        .msg_heading {
            margin: 0;
            color: rgba(0, 0, 0, 0.7);
            font-size: 32px;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
            line-height: 120%;
        }

        .msg {
            margin-top: 18px;
            color: rgba(0, 0, 0, 0.7);
            font-size: 16px;
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
            line-height: 120%;
        }
    }

    .heading {
        width: 100%;
        // text-align: center;

        h2 {
            margin: 0;
            color: rgba(0, 0, 0, 0.7);
            font-size: 20px;
            font-weight: 600;
            font-family: 'Poppins', sans-serif;
            line-height: 120%;
        }

        p {
            margin: 0;
            margin-top: 12px;
            color: rgba(0, 0, 0, 0.7);
            font-size: 15px;
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
            line-height: 120%;
        }

        .indicator {
            font-size: 16px;
        }
    }

    .stepper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 20px;
        margin: 6px 16px 12px 16px;

        .step {
            height: 3px;
            width: 100%;
            background-color: #a8a8a8;
        }

        .active {
            background-color: #000;
        }
    }

    .signin {
        text-align: center;
        margin: 0;
        margin-top: 5px;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        line-height: 120%;
        font-weight: 600;
        color: rgba(33, 37, 41, 0.5);

        button {
            cursor: pointer;
            outline: none;
            border: none;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            line-height: 120%;
            font-weight: 600;
            background: none;
            color: #212529;
        }
    }
}