@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'); */


:root {
    font-family: 'Poppins, san serif';
    background-color: #F9FAFB;
}

body {
    overflow-y: hidden;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.281);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(136, 136, 136, 0.484);
}

.outlined-button {
    border-radius: 5px;
    padding: 6px 20px;
    border-color: #E6E8E7;
}

.outlined-button:hover {
    border-color: #E6E8E7 !important;
}

.outlined-button-rounded {
    border-radius: 100px;
    padding: 5px 20px;
    color: #3EC5F4 !important;
    border-color: #3EC5F4 !important;
}